.add-button {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: #63666a6b;
    color: white;
    font-weight: bold;
    height: 26px;
    font-size: 12px;
}
