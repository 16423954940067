@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {

  .clip-path-dashboard {

    clip-path: ellipse(64% 50% at 50% 50%);

  }

  .dashboard-svg {

    width: 100%;
    //margin: 0 -20%;
    //transform: translate(0px, 70px);

    //z-index: 100;
    position: absolute;
  }

  .marks {
    transform: translate(20px, 20px);
    stroke-width: 0.2;
    font-size: 4px;

    @for $i from 1 through 24 {

      & > text:nth-child(#{$i}) {

      }
    }
  }

  .timer-arrow:before {
    content: "";
    border: 10px solid transparent;
    border-bottom: 10px solid #203d77;
    position: absolute;
    left: calc(50% - 11px);
    transform: translateY(102px);
  }

  .scroll-remover::-webkit-scrollbar {
    display: none;
  }

  img {
    width: 100%; /* or max-width: 100%; */
    height: auto;
  }

  /*.marks > line:nth-child(2) {*/
  /*  transform: rotate(calc(2 * 30deg));*/
  /*}*/


}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: Vazirmatn, serif !important;
  text-align: right;
  direction: rtl;
  background: #FAFAFA;
  overscroll-behavior: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

* {
  box-sizing: border-box;
}

p, span, svg {
  user-select: none;
}
.swiper-button-next:after, .swiper-button-prev:after {
  color: white;
  font-size: 2rem !important;
}
